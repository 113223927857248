.info-secondary-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px !important;
  margin-top: 45px;
  color: #FF1398;
}

.info-secondary-text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 22px !important;
  text-align: left;
}

.info-secondary-container > h2 {
  font-weight: 600;
  line-height: 30px;
}


@media only screen and (min-width: 760px) {
  .info-secondary-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    margin-top: 65px;
  }
  
  .info-secondary-text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 30px !important;
  }
}