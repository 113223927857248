.active {
  width: 70% !important;
  height: 2px !important;
  background-color:#d91a67;

}

.nav-item-underline {
  width: 0%;
  height: 2px;
  background-color:#d91a67;
  margin-left: 44px;
}

.navbar-div{
  background-color:#130034;
  position: fixed !important;
  width: 100%;
  z-index: 9999;

}

.navbar-sticky {
  top: 0;
  height: 64px !important;
  width: 100vw;
  z-index: 99;
  transition: all 0.1s ease;
}

.visible {
  visibility: hidden;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.navbar-container {
  z-index: 99999;
  background-color: #120034;
  display: flex;
  flex-direction: row;
  flex:1;
  padding: 17px 0 17px 68px;
  align-items: center;
}

.navbar-container > a {
  color: white;
  text-decoration: none;
  margin-left: 44px;
  font-size: 14px;
}

.navbar-container > a > img {
  height: 25px;
}

.navbar-menu {
  position: fixed;
  background-color: #19014E;
  width: 100%;
  height: 100dvh;
  top:0;
  right: -100%;
  z-index: 999;
  overflow: hidden;
}

.navbar-menu-opened {
  position: fixed;
  right: 0;
  z-index: 999;
  overflow: hidden;
}

.navbar-menu-closed {
  position: fixed;
  right: -100%;
  z-index: 999;
  overflow: hidden;
}

.navbar-menu, .navbar-menu-opened, .navbar-menu-closed {
  transition: .5s ease-in-out;
}

.navbar-container-logo-small > img {
  width: 50px;
  left: 0;
}

.navbar-container-small {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.navbar-container {
  padding: 20px 20px;
}

.navbar-large {
  display: flex;
}

.link-nav-menu {
  right: -100%;
  position: relative;
  padding: 20px 68px;
}

.link-nav-menu-visible {
  right: 200px !important;
  transition: all .4s ease-in-out;
  text-align: end;
}

.image-bottom {
  z-index: 9;
  bottom: 60px;
  right: -100px !important;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  height: 260px;
}

.image-top {
  top:120px;
  left:-100px !important;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  z-index: 9;
  height: 260px;

  /* margin-top: 60px; */
  /* margin-bottom: 60px; */
}

.navbar-menu-close {
  /* background-color: white; */
  padding: 5px 6px;
  border-radius: 100px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.navbar-open-btn {
  padding: 5px 6px;
  margin: -5px -6px;
  border-radius: 100px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.link-nav-menu-drawer-active {
  font-weight: bold;
  color: #d91a67 !important;
}

.link-nav-menu > a  {
  font-size: 20px !important;
  line-height: 32px;
  text-decoration: none;
  color: white;
}

.navbar-menu-content {
  position: absolute;
  z-index: 999;
  top: 200px;
  right: -220px;
  text-align: right;
}

.navbar-language-container:hover {
  cursor: pointer;
}

@media only screen and (max-width: 400px) {
  .navbar-container-small{
    padding: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-container-small {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 400px) {
  .navbar-container {
    background-color: #120034;
    display: flex;
    flex-direction: row;
    flex:1;
    /* padding: 17px 0 17px 68px; */
    padding: 17px 0 !important;
    align-items: center;
  }

  .navbar-large > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-large > div > a {
    color: white;
    text-decoration: none;
    margin-left: 44px;
    font-size: 14px;
  }
  
  .navbar-large > a > img {
    height: 25px;
  }
  
  .navbar-menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    right: -100%;
    z-index: 999;
    overflow: hidden;
  }
  
  .navbar-menu-opened {
    position: fixed;
    right: 0;
    z-index: 999;
    overflow: hidden;
  }
  
  .navbar-menu-closed {
    position: fixed;
    right: -100% !important;
    z-index: 999;
    overflow: hidden;
  }
  
  .navbar-menu, .navbar-menu-opened, .navbar-menu-closed {
    transition: all .5s ease-in-out;
  }
  
  .navbar-container-logo-small > img {
    width: 50px;
  }
  
  .navbar-container-small {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  
  .navbar-container {
    padding: 0;
  }
}


@media only screen and (min-width: 768px) {
  .navbar-container {
    background-color: #120034;
    display: flex;
    flex-direction: row;
    flex:1;
    padding: 17px 68px 17px 68px !important;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-large > a {
    color: white;
    text-decoration: none;
    /* margin-left: 44px; */
    font-size: 12px;
  }
  
  .navbar-large > a > img {
    height: 25px;
  }
}

@media only screen and (min-width: 1400px) {
  .navbar-container {
    background-color: #120034;
    display: flex;
    flex-direction: row;
    flex:1;
    padding: 17px 68px 17px 68px !important;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-large > a {
    color: white;
    text-decoration: none;
    /* margin-left: 44px; */
    font-size: 14px;

  }
  
  .navbar-large > a > img {
    height: 30px;
  }
}