@keyframes fadeOut {
  from {
    opacity: 1; /* Start with full opacity */
  }
  to {
    opacity: 0; /* Fade out to completely transparent */
    visibility: hidden;
  }
}


.main-loader-container {
  position: 'absolute';
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #110034;
}

.lottie-loader {
  position: absolute;
  z-index: 99;
  /* left:-20px; */
  background-color: #110034;
  object-fit: cover;
  overflow: hidden;
}

.position-fixed {
  position: fixed;
}

.position-initial {
  position: relative !important;
}

.opacity-0-ease {
  animation: fadeOut 1s ease forwards;
}
