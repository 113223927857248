.footer-container {
  background-color: #120034;
  position: relative;
}

.footer-contact-subtitle {
  margin-top: 30px;
}

.footer-container > img {
  position: absolute;
  right: 0px;
  top:  100px;
  /* left: 0px;
  top: 0px; */
  width: 450px;
  z-index: 0;
  transform: scale(1.2);
}

.footer-copyright-container {
  margin-top: 40px;
}

.footer-copyright-container > p {
  color: #7c7c7c;
}

.footer-content-container {
  padding: 100px 20px 20px 20px;
  background-color: #120034;
}

@media screen and (min-width: 768px) {
.footer-content-container {
  padding: 100px 68px 20px 68px;
  background-color: #120034;
}
}
