.android-wedding-camera-container {
  background-image: url('../../../../assets/wedding-app-android-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  opacity: 0.1;
}

.position-fixed {
  background-color: #FAF8F4;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
}

.opacity-10 {
  opacity: 0.1;
}

.wedding-camera-text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.wedding-camera-text-container > p, h3 {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 31px;
}

#imagePicker {
  visibility: hidden;
}

#image-upload-button {
  background-color: #282828;
  color: white;
  padding: 10px 17px;
  border-radius: 30px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#image-upload-button:active {
  opacity: 0.7;
}

#image-upload-button > svg {
  margin-left: 10px;
}

.wedding-camera-logo {
  margin-bottom: 50px;
}