.send-message-container {
  width: 100vw;
  display: flex;
  background-image: url('../../../assets/send-msg-bg-small.png');
  background-size: cover;
  flex-direction: column;
  object-fit: contain;
}

.send-message-btns-container {
  display: flex;
  margin-top: 33px;
}

.send-message-content {
  padding: 100px 20px;
}

.send-message-content > p {
  margin-top: 24px;
  line-height: 24px !important;
}


@media only screen and (min-width: 760px) {
  .send-message-container {
    width: 100vw;
    display: flex;
    background-image: url('../../../assets/contact-bg.png');
    mask-repeat: no-repeat;
    flex-direction: column;
  }
  
  .send-message-btns-container {
    display: flex;
    margin-top: 33px;
  }
  
  .send-message-content {
    padding: 100px 68px;
  }
}

@media only screen and (min-width: 1400px) {
  .send-message-container {
    width: 100vw;
    display: flex;
    background-image: url('../../../assets/contact-bg.png');
    mask-repeat: no-repeat;
    flex-direction: column;
  }
  
  .send-message-btns-container {
    display: flex;
    margin-top: 33px;
  }
  
  .send-message-content {
    padding: 100px 68px;
  }
}