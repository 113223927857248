.hero-container {
  margin-top: 60px;
  position: relative;
  background-image: url('../../../assets/hero_bg.png');
  background-size: cover;
}

.hero-container-mobile {
  background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),  url('../../../assets/hero-mobile.png') !important;
  background-size: cover;
  margin-top: 60px;
  position: relative;

}

.hero-image {
  width: 100vw;
  height: 90vh;
  /* opacity: 60%; */
}

.hero-title-container {
  /* position: absolute; */
}

.hero-text {
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.hero-main-title {
  text-align: center;
  margin-bottom: 30px;
}

.hero-sub-title {
  text-align: center;
}

.hero-text-left {
  padding: 25dvh 20px;
}

.hero-title-content-left {
  padding: 0 20px
}

.hero-title-content-left > .hero-text-left {
  left: 68px;
  width: 100%;
  font-size: 40px;
  line-height: 55px;
  text-align: left;

}

.hero-title-content-left > .hero-text-left > .hero-main-title-left {
  margin-bottom: 30px;
}

@media only screen and (max-width: 400px) {
  .hero-text-left {
    padding: 25dvh 20px;
  }  
}

@media only screen and (max-width: 1000px) {

  .hero-title-content-left > .hero-text-left {
    /* text-align: center; */
    left: 68px;
    width: 100% !important;
    font-size: 40px;
    line-height: 55px;

    text-align: center;
  }

  .hero-title-content-left > .hero-text-left > .hero-main-title-left {
    margin-bottom: 30px;
    text-align: center;

  }
}



@media only screen and (min-width: 770px) {
  .hero-container {
    position: relative;
  }
  
  .hero-image {
    width: 100vw;
    height: 90vh;
  }
  
  .hero-title-container {
    /* position: absolute; */
  }
  
  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hero-main-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .hero-sub-title {
    text-align: center;
  }

  .hero-text-left {
    padding: 30vh 68px;
  }

  .hero-title-content-left > .hero-text-left {
    left: 68px;
    width: 80%;
    font-size: 40px;
    line-height: 55px;

  }

  .hero-title-content-left > .hero-text-left > .hero-main-title-left {
    margin-bottom: 30px;
  }
}