.who-we-are-container {
  padding: 100px 20px;
}

.who-we-are-title {
  margin-bottom: 100px;
}

.about-employee-row {
  align-items: start;
}

@media screen and (min-width: 768px){
  .who-we-are-container {
    padding: 100px 68px;
  }
}