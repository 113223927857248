.info-container {
  padding: 0px 20px 0px 20px;
  margin-top: 160px;
}

.info-image-project {
  flex: 0.6 !important; 
}

.info-image-container {
  flex: 0.4;
  /* padding-left: 88px; */
  align-content: center;
}

.image-left {
  padding-left: 0px;
  padding-right: 88px;
}

.info-image, .info-image-center {
  width: 100%;
  border-radius: 20px;
}

.info-text-container {
  flex: 0.6;
}

.info-text-title {
  font-size: 24px;
  line-height: 32px;
}

.info-text-subtitle {
  margin-top: 45px;
  line-height: 22px;
  text-align: left;
  /* hyphens: auto; */
}

.info-image-container-vertical {
  display: flex;
  flex-direction: column;

}

.image-center {
  width: 100%;
  display: flex;
  align-self: center;
  position: relative;
  justify-content: center;
}

.info-image-center {
  /* width: 300px; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.info-text-container {
  /* text-align:center; */
}

.mouse-hover {
  cursor: pointer;
}

@media only screen and (min-width: 760px) {
  .info-container {
    padding: 0px 68px 0px 68px;
    margin-top: 260px;
    display: flex;
    flex-direction: row;
  }
  
  .info-image-project {
    flex: 0.6 !important; 
  }

  .info-image-container {
    flex: 0.4;
    align-content: center;
  }
  
  .image-center {
    margin-bottom: 50px;
  }

  .info-image-center {
    width: 100% !important;
    object-fit: cover;
    border-radius: 40px;
  }


  .image-left {
    padding-left: 0px;
    padding-right: 88px;
  }
  
  .image-right {
    padding-right: 0px;
    padding-left: 88px;
  }

  .info-image {
    width: 100%;
  }
  
  .info-text-container {
    flex: 0.6;
  }
  
  .info-text-title {
    font-size: 30px;
    line-height: 40px;
  }
  
  .info-text-subtitle {
    margin-top: 45px;
    line-height: 30px;
  }
  
  .info-image-container-vertical {
    display: flex;
    flex-direction: column;
  }
  
  .info-image-container-vertical > .image-center {
    width: 100%;
    display: flex;
    align-self: center;
    position: relative;
    justify-content: center;
  }
  
  .info-image-container-vertical > .image-center > img {
    width: 60%;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1000px){
  .info-container {
    padding: 0px 68px 0px 68px;
    margin-top: 260px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
  }

  .info-image-project {
    flex: 0.6 !important; 
  }
  
  .info-image-container {
    flex: 0.4;
    padding-left: 88px;
    align-content: center;
  }
  
  .image-left {
    padding-left: 0px;
    padding-right: 88px;
  }
  
  .info-image {
    width: 100%;
  }
  
  .info-text-container {
    flex: 0.6;
  }
  
  .info-text-title {
    font-size: 40px;
    line-height: 55px;
  }
  
  .info-text-subtitle {
    margin-top: 35px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1400px) {
  .info-container {
    padding: 0px 68px 0px 68px;
    margin-top: 260px;
    display: flex;
    flex-direction: row;
  }

  .info-image-project {
    flex: 0.6 !important; 
  }
  
  .info-image-container {
    flex: 0.4;
    padding-left: 88px;
    align-content: center;
  }
  
  .image-left {
    padding-left: 0px;
    padding-right: 88px;
  }
  
  .info-image {
    width: 100%;
  }
  
  .info-text-container {
    flex: 0.6;
    align-content: center;
  }
  
  .info-text-title {
    font-size: 40px;
    line-height: 55px;
  }
  
  .info-text-subtitle {
    margin-top: 35px;
    line-height: 30px;
  }
}