h1 {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: bold;
  color: white;
}

h2 {
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 5%;
  line-height: 24px;
  font-weight: 400;
  color: white;
  margin: 0;
} 


p, a, li {
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 5%;
  line-height: 24px;
  font-weight: 400;
  color: white;
  margin: 0;
}

.title-md {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: white;
}

.subtitle {
  font-size: 18px;
  line-height: 35px;
  font-family: "Montserrat";
  color: white;
}

.spacer100 {
  width: 100px;
}

.spacer20 {
  width: 20px;
}

.send-message-content > .title-md {
  text-align: start;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: bold;
    color: white;
  }
  
  h2, p, a, li {
    font-family: "Montserrat";
    font-size: 14px;
    letter-spacing: 5%;
    line-height: 30px;
    font-weight: 400;
    color: white;
    margin: 0;
  }
  
  .title-md {
    font-family: "Montserrat";
    font-size: 30px;
    line-height: 55px;
    font-weight: bold;
    color: white;
  }
  
  .subtitle {
    font-size: 18px;
    line-height: 35px;
    font-family: "Montserrat";
    color: white;
  }
  
  .spacer100 {
    width: 100px;
  }
  
  .spacer20 {
    width: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  h1 {
    font-family: "Montserrat";
    font-size: 50px;
    font-weight: bold;
    color: white;
  }
  
  h2, p, a, li {
    font-family: "Montserrat";
    font-size: 16px;
    letter-spacing: 5%;
    line-height: 30px;
    font-weight: 400;
    color: white;
    margin: 0;
  }
  
  .title-md {
    font-family: "Montserrat";
    font-size: 40px;
    line-height: 55px;
    font-weight: bold;
    color: white;
  }
  
  .subtitle {
    font-size: 20px;
    line-height: 35px;
    font-family: "Montserrat";
    color: white;
  }
  
  .spacer100 {
    width: 100px;
  }
  
  .spacer20 {
    width: 20px;
  }
}