.body-container {
  background-color: #120034;
  overflow: hidden;
  position: fixed;
}

.info-text-info-secondary-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: start;
}

.info-content-list-item {
  padding: 35px 40px 0px 40px;
}

li::marker {
  color: #FF1398;
}

@media only screen and (min-width: 500px) {
  .body-container {
    background-color: #120034;
    overflow: hidden;
  }
  
  .info-text-info-secondary-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
