.about-employee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-employee-image {
  width: 50%;
}

.about-employee-text-container {
  margin-top: 30px;
  width: 100%;
}

.about-employee-text-container > p {
  line-height: 24px;
  text-align: justify;
  hyphens: auto;

}

.about-employee-text-container > h3 {
  margin-bottom: 30px;
}

.about-employee-text-href {
  text-decoration: underline solid #FF1398 0.5px;
}


@media only screen and (min-width: 1000px) {
  .about-employee-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .about-employee-image {
    width: 50%;
  }
  
  .about-employee-text-container {
    margin-top: 30px;
    width: 80%;
  }
  
  .about-employee-text-container > p {
    line-height: 24px;
  }
  
  .about-employee-text-container > h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 768px){
  .about-employee-text-container {
    width: 80%;
  }
}